<template>
  <div
    v-if="loading"
    :class="{
      'd-flex justify-content-center full-height align-items-center': true,
      'full-width': fullWidth,
    }"
  >
    <div class="loader m-t-45" :style="loaderStyle" />
  </div>
</template>

<script>
export default {
  name: "PgLoading",
  description: "shows a centered spinning icon based on state",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      default: "#6434D0",
    },
    size: {
      type: String,
      default: "lg",
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    loaderStyle() {
      const styles = {
        background: `radial-gradient(farthest-side, ${this.color} 94%, #0000) top/8px 8px no-repeat, conic-gradient(#0000 30%, ${this.color})`,
      };

      if (this.size === "sm") {
        styles.width = "24px";
        styles.marginTop = 0;
      }
      return styles;
    },
  },
};
</script>

<style>
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}
</style>
